import { Component, EventEmitter, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { ContextService } from 'src/services/platform/context.service';
import { ProProfile, jobHistory } from 'src/ts/interfaces/profile.interface';
import { dropdown } from 'src/ts/interfaces/shared.interface';

@Component({
  selector: 'app-job-history',
  templateUrl: './job-history.component.html',
  styleUrls: ['./job-history.component.scss'],
})
export class JobHistoryComponent {
  industries = this.contextService.profileData.industries;
  faTrashCan = faTrashCan;
  error: string[] = [];
  jobHistory: jobHistory[] = this.contextService.jobHistory;
  startNonSelected: boolean = false;
  currentDate = new Date();
  @Output() callbackFunction = new EventEmitter<any>();

  constructor(private contextService: ContextService) {}

  removeJob(index: number) {
    this.contextService.jobHistory = this.jobHistory.filter((x, i) => {
      return index !== i;
    });
    this.jobHistory = this.contextService.jobHistory;
  }

  nonSelected(type: string, index: number, error: string[] = []) {
    return error?.includes(type);
  }

  addPosition() {
    this.jobHistory.push({
      role: '',
      company: '',
      industryId: '',
      current: false,
      start: '',
    });
    this.callbackFunction.emit('jobHistory');
  }

  setMonthYear(event: { type: string; date: string; index: number }) {
    const etype = event.type as keyof jobHistory;
    if (event.date) {
      const d = new Date(event.date);
      if (event.type === 'start') {
        if (this.jobHistory[event.index].end) {
          const endDate = new Date(this.jobHistory[event.index].end!);
          if (d.valueOf() > endDate.valueOf()) {
            this.jobHistory[event.index].end = '';
          }
        }
      }
      if (etype === 'end') {
        if (this.jobHistory[event.index].start) {
          const startDate = new Date(this.jobHistory[event.index].start!);
          if (d.valueOf() < startDate.valueOf()) {
            this.jobHistory[event.index].start = '';
          }
        }
        this.flip(
          { checked: d.valueOf() > this.currentDate.valueOf() },
          event.index
        );
      }
    }

    if (etype !== 'current' && etype !== 'error') {
      this.jobHistory[event.index][etype] = event.date;
      if (event.date !== undefined) {
        this.validate(event.date, event.type, event.index);
      }
    }
    this.validate(event.date, event.type, event.index);
  }

  input(event: Event, index: number, type: keyof jobHistory) {
    const element = event.target as HTMLInputElement;

    if (type !== 'current' && type !== 'error') {
      this.jobHistory[index][type] = element.value;
    }
  }

  validate(event: Event | string, type: string, index: number) {
    let value = event as string;
    if (typeof event !== 'string') {
      value = (event?.currentTarget as HTMLInputElement)?.value ?? '';
    }

    if (type === 'end' && this.jobHistory[index].end === '') {
      this.jobHistory[index].current = true;
      return;
    }
    let findex = this.jobHistory[index].error?.findIndex((x) => {
      return x === type;
    });

    if (!findex && findex !== 0) {
      findex = -1;
    }
    if (findex > -1) {
      this.jobHistory[index].error?.splice(findex, 1);
    }
    if (value.length < 1) {
      if (!this.jobHistory[index].error) {
        this.jobHistory[index].error = [type];
      } else {
        this.jobHistory[index].error?.push(type);
      }
    }
    this.callbackFunction.emit('jobHistory');
  }
  // selectIndustry(selected: ProProfile, index: number) {
  //   if (!selected.id) {
  //     this.validate('', 'industryId', index);
  //     return;
  //   } else {
  //     this.jobHistory[index].industryId = selected.id;
  //     this.validate(JSON.stringify(selected.id), 'industryId', index);
  //   }
  // }
  selectIndustry(selected: ProProfile, index: number) {
    if (!selected.id) {
      this.validate('', 'industryId', index);
      return;
    } else {
      if (!this.jobHistory[index]) {
        this.jobHistory[index] = {
          role: '',
          company: '',
          industryId: '',
          current: true,
          start: '',
        };
      }
      this.jobHistory[index].industryId = selected.id;
      this.validate(JSON.stringify(selected.id), 'industryId', index);
    }
  }

  prevent(event: Event, index: number) {
    return;
    if (this.jobHistory[index].end === '') {
      event.preventDefault();
    }
    if (this.jobHistory[index].end) {
      const d = new Date(this.jobHistory[index].end!);
      event.preventDefault();
      this.jobHistory[index].current = d.valueOf() > this.currentDate.valueOf();
    }
  }
  flip(event: MatSlideToggleChange | { checked: boolean }, index: number) {
    this.jobHistory[index].current = event.checked;
    if (event.checked) {
      this.jobHistory[index].end = '';
    }
  }
  getSelectedIndustry(id: string | number, index: number) {
    const find = this.industries.find((x) => x.id === id);
    if (find) {
      return find as dropdown;
    }
    return { displayName: '' };
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faSpinner,
  faArrowRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import { ApiService } from 'src/services/api/api.service';
import { ContextService } from 'src/services/platform/context.service';
import { PlatformService } from 'src/services/platform/platform.service';
import { ValidationService } from 'src/services/platform/validation.service';
import {
  navigationMenu,
  stepsMenuSlug,
} from 'src/ts/interfaces/profile.interface';
import { buttons } from 'src/ts/interfaces/shared.interface';

import lang from '../../../assets/languages';
import { Location } from '@angular/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  openSidePanel = true;
  lang = lang.list;
  faSpinner = faSpinner as IconProp;
  selectedNav: string = '';
  messages: any = {
    success: 'Your data was saved',
    warning: 'We could not save your data',
    error: 'Something is wrong',
  };
  message: string = '';
  hideMessage: boolean = true;
  readyForSubmit: boolean = false;
  isSubmited: boolean = false;
  loading: boolean = true;
  openGame: boolean = false;
  stepNumber = 1;
  editButtons: buttons[] = [
    { title: 'Cancel', type: 'cancel', class: 'secondary' },
    { title: 'Save', type: 'save', class: 'primary', disable: true },
  ];
  disableSave = false;
  stepsMenuSlug: stepsMenuSlug;
  languages: any;
  buttons: buttons[] = [];
  skip: buttons[] = [];
  navs: navigationMenu[];
  counter: { length: number; current: number } = {
    length: 0,
    current: 0,
  };
  @Input() edit: string = '';
  @Input() toEdit: string[] = [];
  @Output() closeDialog = new EventEmitter<any>();
  @ViewChild('rightContent') rightContent: ElementRef;
  constructor(
    private router: Router,
    public platformService: PlatformService,
    public contextService: ContextService,
    public apiService: ApiService,
    public validationService: ValidationService,
    private location: Location
  ) {
    this.platformService.reRender.subscribe((val) => {
      if (val) {
        let valid = this.contextService.validSelection;
        this.editButtons[1].disable = true;
        if (this.edit) {
          if (this.edit === this.stepsMenuSlug.roles) {
            if (
              valid['asConsultant'] ||
              valid['jobFamily'] ||
              valid['levelWork'] ||
              valid['skills'] ||
              valid['topSkills'] ||
              valid['yearExperience']
            ) {
              this.editButtons[1].disable = false;
            }
          }
          if (this.edit === this.stepsMenuSlug.income) {
            if (valid['rate'] || valid['salary'] || valid['currency']) {
              this.editButtons[1].disable = false;
            }
          }
          if (this.edit === this.stepsMenuSlug.sweetspot) {
            if (valid['sweetSpot']) {
              this.editButtons[1].disable = false;
            }
          }
          if (this.edit === this.stepsMenuSlug.experience) {
            if (valid['jobHistory']) {
              this.editButtons[1].disable = false;
            }
          }
          if (this.edit === this.stepsMenuSlug.technologies) {
            if (valid['technologies']) {
              this.editButtons[1].disable = false;
            }
          }
          if (this.edit === this.stepsMenuSlug.languages) {
            if (this.contextService.selectedProProfile.languages.length === 0) {
              this.editButtons[1].disable = false;
            }
          }
          if (this.edit === this.stepsMenuSlug.location) {
            if (valid['city'] || valid['workFrom'] || valid['startDate']) {
              this.editButtons[1].disable = false;
            }
          }
        }
        this.setValid();
      }
    });

    this.contextService.dataLoaded.subscribe((val) => {
      if (val === 'success') {
        this.navs = this.contextService.stepsMenu;
        this.counter.length = this.navs.length;
        validationService.getProcentage();
        this.languages = this.contextService.languages?.submit.done;
        this.stepsMenuSlug = this.contextService.stepsMenuSlug;
        this.loading = false;
        if (sessionStorage.getItem('profile-flow')) {
          if (sessionStorage.getItem('profile-flow') === 'submit') {
            this.selectedNav = this.stepsMenuSlug.welcome;
          } else {
            this.selectedNav = sessionStorage.getItem('profile-flow')!;
          }
        } else {
          this.selectedNav = this.stepsMenuSlug.welcome;
        }
        this.openSidePanel = window.innerWidth >= 1164;
        this.counter.current =
          this.navs.findIndex((x) => x.slug === this.selectedNav) + 1;

        this.setValid();
      }
    });
  }

  onResized(event: any) {
    this.openSidePanel = event.currentTarget!.innerWidth >= 1164;
  }

  home() {
    let findFirst = false;
    this.contextService.stepsMenu.forEach((val) => {
      if (!val.state && !findFirst) {
        findFirst = true;
        sessionStorage.setItem('profile-flow', val.slug);
      }
    });

    this.platformService.getData(this.contextService.accessTokens);
    this.router.navigate(['/home'], { queryParamsHandling: 'preserve' });

  }

  toggleSide() {
    this.openSidePanel = !this.openSidePanel;
  }

  ngOnInit(): void {}

  async click(button: buttons, edit?: string[]) {
    if (button.disable) {
      return;
    }
    const type = button.type;
    if (edit) {
      if (type === 'save') {
        let valid: string[] = [];
        for (let i = 0; i < edit.length; i++) {
          await this.saveData(edit[i]).then((response) => {
            valid.push(response);
            return;
          });
        }
        if (valid.some((x) => x !== 'success')) {
          this.validateStep('error');
        } else {
          this.validateStep('success');
          this.closeDialog.emit();
          this.platformService.reRender.next('save');
          this.platformService.getData(this.contextService.accessTokens);
        }

        return;
      }

      if (this.platformService.reRender.value !== '') {
        this.platformService.getData(this.contextService.accessTokens);
      }
      this.closeDialog.emit();
      return;
    }
    if (type === 'finish') {
      this.location.back();
    }
    if (type === 'submit') {
      this.submit();
    }
    if (type === 'next') {
      this.next();
    }
    if (type === 'previous') {
      this.previous();
    }
    if (type === 'play') {
      this.play();
    }
  }
  play() {
    this.openGame = !this.openGame;
  }
  apiCall() {}
  // reRender(edit: string) {
  //   this.edit = 'render'
  //   setTimeout(() => {
  //     this.edit = edit
  //   }, 0)
  // }
  saveData(type: string): Promise<string> {
    const messages = ['success', 'warning', 'error'];
    return new Promise(async (resolve, reject) => {
      let valid = false;
      if (
        type === this.stepsMenuSlug.welcome ||
        type === this.stepsMenuSlug.finish ||
        type === this.stepsMenuSlug.game
      ) {
        valid = true;
        this.setValid();

        resolve('none');
        return;
      }
      if (type === this.stepsMenuSlug.roles) {
        valid = true;
        let updatejobfamily: any = '';
        const validData = this.contextService.levelOfExperience;
        let yearExperience: any = '';
        let setworkedasconsultantbefore: any = '';
        if (this.contextService.selectedProProfile.asConsultant.length > 0) {
          setworkedasconsultantbefore = await this.apiService.saveGenome(
            'proxy/setworkedasconsultantbefore',
            {
              value:
                this.contextService.selectedProProfile.asConsultant[0] === 1,
            },
            this.contextService.accessTokens.genome
          );
        }
        if (this.contextService.selectedProProfile.yearExperience.length > 0) {
          const ye = this.contextService.proProfile().yearExperience;
          const findIndex = ye.findIndex(
            (x) =>
              x.id === this.contextService.selectedProProfile.yearExperience[0]
          );
          yearExperience = await this.apiService.saveIndustries(
            'proxy/updateyearsofexperience',
            {
              value: validData[findIndex],
            },
            this.contextService.accessTokens.industries
          );
        }
        if (this.contextService.selectedProProfile.jobFamily.length > 0) {
          updatejobfamily = await this.apiService.saveSkills(
            'proxy/updatejobfamily',
            { id: this.contextService.selectedProProfile.jobFamily[0] },
            this.contextService.accessTokens.skills
          );
        }
        const levelWork = this.contextService.proProfile().levelWork;
        const findIndex = levelWork.findIndex(
          (x) => x.id === this.contextService.selectedProProfile.levelWork[0]
        );
        let displayName = '';
        if (findIndex !== -1) {
          displayName = levelWork[findIndex].displayName;
        }
        let updatelevelofexperience: any = '';
        if (displayName.length > 0) {
          updatelevelofexperience = await this.apiService.saveGenome(
            'proxy/updatelevelofexperience',
            { value: displayName },
            this.contextService.accessTokens.genome
          );
        }
        if (
          [
            updatejobfamily,
            updatelevelofexperience,
            yearExperience,
            setworkedasconsultantbefore,
          ].some((x) => x !== true)
        ) {
          resolve(messages[2]);
        } else {
          this.setValid();
          resolve(messages[0]);
        }

        // Promise.all([updatejobfamily, updatelevelofexperience]).then(
        //   (value) => {
        //     if (value.some((x) => x !== true)) {
        //       resolve(messages[2])
        //       return
        //     }
        //     this.setValid()
        //     resolve(messages[0])
        //   },
        // )
      }

      if (type === this.stepsMenuSlug.allSkills) {
        valid = true;
        let updateSkills: any = '';
        if (this.contextService.selectedProProfile.skills.length > 0) {
          updateSkills = this.apiService.saveSkills(
            'proxy/updateavailableskills',
            { selectedSkills: this.contextService.selectedProProfile.skills },
            this.contextService.accessTokens.skills
          );
        }

        Promise.all([updateSkills]).then((value) => {
          if (value.some((x) => x !== true)) {
            resolve(messages[2]);
            return;
          }
          this.setValid();
          resolve(messages[0]);
        });
      }
      if (type === this.stepsMenuSlug.topSkills) {
        valid = true;
        let updateTopSkills: any = '';
        if (this.contextService.selectedProProfile.topSkills.length > 0) {
          updateTopSkills = this.apiService.saveSkills(
            'proxy/updatetopskills',
            {
              selectedSkills: this.contextService.selectedProProfile.topSkills,
            },
            this.contextService.accessTokens.skills
          );
        }

        Promise.all([updateTopSkills]).then((value) => {
          if (value.some((x) => x !== true)) {
            resolve(messages[2]);
            return;
          }
          this.setValid();
          resolve(messages[0]);
        });
      }
      if (type === this.stepsMenuSlug.sweetspot) {
        valid = true;
        let sweetSpot: any = '';
        if (
          this.contextService.selectedProProfile.sweetSpot.some((x) => x !== -1)
        ) {
          sweetSpot = this.apiService.saveGenome(
            'proxy/updateworkingsweetspotdimensions',
            {
              dimension1Value:
                this.contextService.selectedProProfile.sweetSpot[0],
              dimension2Value:
                this.contextService.selectedProProfile.sweetSpot[1],
              dimension3Value:
                this.contextService.selectedProProfile.sweetSpot[2],
              dimension4Value:
                this.contextService.selectedProProfile.sweetSpot[3],
              dimension5Value:
                this.contextService.selectedProProfile.sweetSpot[4],
              dimension6Value:
                this.contextService.selectedProProfile.sweetSpot[5],
            },
            this.contextService.accessTokens.genome
          );
        }

        Promise.all([sweetSpot]).then((value) => {
          if (value.some((x) => x !== true)) {
            resolve(messages[2]);
            return;
          }
          this.setValid();
          resolve(messages[0]);
        });
      }
      if (type === this.stepsMenuSlug.experience) {
        valid = true;
        let industriesExperience: any = '';
        const validData = this.contextService.levelOfExperience;
        // let yearExperience: any = ''
        // if (this.contextService.selectedProProfile.yearExperience.length > 0) {
        //   const ye = this.contextService.proProfile().yearExperience
        //   const findIndex = ye.findIndex(
        //     (x) =>
        //       x.id === this.contextService.selectedProProfile.yearExperience[0],
        //   )
        //   yearExperience = await this.apiService.saveIndustries(
        //     'proxy/updateyearsofexperience',
        //     {
        //       value: validData[findIndex],
        //     },
        //     this.contextService.accessTokens.industries,
        //   )
        // }
        let jobHistory: any = '';

        if (this.contextService.jobHistory.length > 0) {
          this.contextService.jobHistory.map((x) => {
            if (x.end === '') {
              delete x.end;
            }
            return x;
          });
          jobHistory = await this.apiService.saveIndustries(
            'proxy/jobhistory',
            { jobHistory: this.contextService.jobHistory },
            this.contextService.accessTokens.industries
          );
        }

        // if (this.contextService.selectedIndustries.industries.length > 0) {
        //   const selectedIndustries: object[] = [];

        //   this.contextService.selectedIndustries.industries.forEach((val) => {
        //     const ye = this.contextService.proProfile().yearExperience;
        //     const findIndex = ye.findIndex(
        //       (x) => x.displayName === val.selected!.experience
        //     );
        //     let data = {
        //       id: val.id,
        //       rank: val.selected!.rank,
        //       levelOfExperience:
        //         findIndex === -1 ? validData[0] : validData[findIndex],
        //     };
        //     selectedIndustries.push(data);
        //   });
        //   industriesExperience = await this.apiService.saveIndustries(
        //     'proxy/updateselectedindustries',
        //     { selectedIndustries: selectedIndustries },
        //     this.contextService.accessTokens.industries
        //   );
        // }
        if ([jobHistory].some((x) => x !== true)) {
          resolve(messages[2]);
        } else {
          this.setValid();
          resolve(messages[0]);
        }
        // Promise.all([yearExperience, industriesExperience]).then((value) => {
        //   if (value.some((x) => x !== true)) {
        //     resolve(messages[2])
        //     return
        //   }
        //   this.setValid()
        //   resolve(messages[0])
        // })
      }

      if (type === this.stepsMenuSlug.technologies) {
        valid = true;
        let updateTech: any = '';
        if (this.contextService.selectedProProfile.technologies.length > 0) {
          updateTech = this.apiService.saveSkills(
            'proxy/updatetechnologyskills',
            {
              selectedSkills:
                this.contextService.selectedProProfile.technologies,
            },
            this.contextService.accessTokens.skills
          );
        }

        Promise.all([updateTech]).then((value) => {
          if (value.some((x) => x !== true)) {
            resolve(messages[2]);
            return;
          }
          this.setValid();
          resolve(messages[0]);
        });
      }
      if (type === this.stepsMenuSlug.languages) {
        valid = true;
        let updatelanguages: any = '';

        if (this.contextService.selectedProProfile.languages.length > 0) {
          let selectedLanguages: string[] = [];
          this.contextService.selectedProProfile.languages.forEach((id) => {
            selectedLanguages.push(
              this.lang.find((x) => x.id === id)?.countryCode!
            );
          });

          updatelanguages = this.apiService.saveGenome(
            'proxy/updateworkinglanguages',
            {
              values: selectedLanguages,
            },
            this.contextService.accessTokens.genome
          );
        }

        Promise.all([updatelanguages]).then((value) => {
          if (value.some((x) => x !== true)) {
            resolve(messages[2]);
            return;
          }
          this.setValid();
          resolve(messages[0]);
        });
      }

      if (type === this.stepsMenuSlug.location) {
        valid = true;
        let updateCity: any = '';
        let updateWorkFrom: any = '';

        if (this.contextService.selectedCity?.displayName.length > 0) {
          updateCity = await this.apiService.saveGenome(
            'proxy/updatelocation',
            {
              country: this.contextService.selectedCity.country,
              city: this.contextService.selectedCity.displayName,
              state: this.contextService.selectedCity.state,
            },
            this.contextService.accessTokens.genome
          );
        }
        if (this.contextService.selectedProProfile.workFrom.length > 0) {
          updateWorkFrom = await this.apiService.saveGenome(
            'proxy/updateworkingpreferences',
            {
              remote: this.contextService.selectedProProfile.workFrom.some(
                (x) => x === 1
              ),
              onSite: this.contextService.selectedProProfile.workFrom.some(
                (x) => x === 2
              ),
              hybrid: this.contextService.selectedProProfile.workFrom.some(
                (x) => x === 3
              ),
            },
            this.contextService.accessTokens.genome
          );
        }

        if ([updateCity, updateWorkFrom].some((x) => x !== true)) {
          resolve(messages[2]);
        } else {
          this.setValid();
          resolve(messages[0]);
        }
        // Promise.all([updateCity, updateWorkFrom, updateAvailability]).then(
        //   (value) => {
        //     if (value.some((x) => x !== true)) {
        //       resolve(messages[2])
        //       return
        //     }
        //     this.setValid()
        //     resolve(messages[0])
        //   },
        // )
      }

      if (type === this.stepsMenuSlug.income) {
        valid = true;
        let updateAvailability: any = '';
        let updateexpectedincome: any = '';

        if (
          this.contextService.selectedProProfile.rate.length > 0 &&
          this.contextService.selectedSalery.salary &&
          this.contextService.selectedSalery.currency
        ) {
          updateexpectedincome = await this.apiService.saveGenome(
            'proxy/updateexpectedincome',
            {
              type:
                this.contextService.selectedProProfile.rate[0] === 1
                  ? 'hourly'
                  : 'monthly',
              value: this.contextService.selectedSalery.salary,
              currency: this.contextService.selectedSalery.currency,
            },
            this.contextService.accessTokens.genome
          );
        }

        if (this.contextService.selectedStartDate.availabilityDate) {
          let d = new Date(
            this.contextService.selectedStartDate.availabilityDate
          );

          updateAvailability = await this.apiService.saveGenome(
            'proxy/updateavailability',
            {
              availabilityDate:
                d.getFullYear() +
                '-' +
                ('0' + (d.getMonth() + 1)).slice(-2) +
                '-' +
                ('0' + d.getDate()).slice(-2),
              fullTime:
                this.contextService.selectedProProfile.workTime.includes(1),
              partTime:
                this.contextService.selectedProProfile.workTime.includes(2),
            },
            this.contextService.accessTokens.genome
          );
        }

        if (
          [updateexpectedincome, updateAvailability].some((x) => x !== true)
        ) {
          resolve(messages[2]);
        } else {
          this.setValid();
          resolve(messages[0]);
        }
        // Promise.all([setworkedasconsultantbefore, updateexpectedincome]).then(
        //   (value) => {
        //     if (value.some((x) => x !== true)) {
        //       resolve(messages[2])
        //       return
        //     }
        //     this.setValid()
        //     resolve(messages[0])
        //   },
        // )
      }

      if (type === this.stepsMenuSlug.profPro) {
        valid = true;
        let updateprofessionalprofiles: any = '';
        let professionalprofiles: {
          [key: string]: string;
        } = {};
        this.contextService.proProfile().profile.forEach((val) => {
          let find = this.contextService.selectedProfProfile.find(
            (x) => x.id === val.id
          );
          if (find) {
            professionalprofiles[val.displayName] = find.name;
          }
        });
        // this.contextService.proProfile().profile
        updateprofessionalprofiles = this.apiService.saveGenome(
          'proxy/updateprofessionalprofiles',
          professionalprofiles,
          this.contextService.accessTokens.genome
        );
        // }
        Promise.all([updateprofessionalprofiles]).then((value) => {
          if (value.some((x) => x !== true)) {
            resolve(messages[2]);
            return;
          }
          this.setValid();
          resolve(messages[0]);
        });
      }

      if (!valid) {
        resolve(messages[1]);
      }
    });
  }

  validateStep(result: string) {
    this.message = result;
    this.hideMessage = false;
    setTimeout(() => {
      this.hideMessage = true;
    }, 2000);
  }

  async previous() {
    // await new Promise((resolve) => {
    //   this.saveData(this.selectedNav).then((response) => {
    //     this.validateStep(response);
    //     resolve(true);
    //   });
    // });

    let findex = this.navs.findIndex((x) => x.slug === this.selectedNav);
    if (findex - 1 >= 0) {
      this.selectedNav = this.navs[findex - 1].slug;
    } else {
      this.selectedNav = '';
    }
    sessionStorage.setItem('profile-flow', this.selectedNav);
    this.stepNumber =
      this.navs.findIndex((x) => x.slug === this.selectedNav) + 1;
    this.setButtons(this.selectedNav);
    this.rightContent.nativeElement.scrollTop = 0;
    window.scrollTo(0, 0);
  }

  async next(skip?: boolean) {
    const resolve = await new Promise((resolve, reject) => {
      this.saveData(this.selectedNav).then((response) => {
        if (response === 'error') {
          reject(response);
        }
        this.validateStep(response);
        resolve(true);
      });
    }).catch((error) => {
      return error;
    });
    if (resolve === 'error' && !skip) {
      return;
    }
    let findex = this.navs.findIndex((x) => x.slug === this.selectedNav);
    if (findex !== -1) {
      this.selectedNav = this.navs[findex + 1].slug;
    }
    if (this.selectedNav === '') {
      this.selectedNav = this.navs[0].slug;
    }
    sessionStorage.setItem('profile-flow', this.selectedNav);
    // this.step(this.selectedNav)
    this.setButtons(this.selectedNav);
    this.stepNumber =
      this.navs.findIndex((x) => x.slug === this.selectedNav) + 1;
    this.rightContent.nativeElement.scrollTop = 0;
    window.scrollTo(0, 0);
    // this.buttons[1].disable = !this.navs[this.stepNumber - 1].state!;
  }

  submit() {
    if (this.readyForSubmit) {
      this.step('submit');
      this.apiService
        .saveGenome('proxy/submit', {}, this.contextService.accessTokens.genome)
        .then(() => {
          this.isSubmited = true;
        });
    }
  }

  setButtons(value?: string) {
    value = value ? value : sessionStorage.getItem('profile-flow') ?? '';
    if (value === 'finish-profile') {
      if (this.contextService.sevenNConsulatant) {
        this.buttons = [
          { title: 'Previous', type: 'previous', class: 'secondary' },
          {
            title: 'Finish profile',
            type: 'finish',
            class: 'primary',
            disable: !this.readyForSubmit,
          },
        ];
      }
      return;
    }
    if (value === 'submit-for-approval') {
      if (!this.contextService.sevenNConsulatant) {
        this.buttons = [
          { title: 'Previous', type: 'previous', class: 'secondary' },
          {
            title: 'Submit for approval',
            type: 'submit',
            class: 'primary',
            disable: !this.readyForSubmit,
          },
        ];
      }
      return;
    }

    this.stepNumber = this.navs.findIndex((x) => x.slug === value) + 1;
    this.counter.current = this.stepNumber;

    this.buttons = [
      {
        title: 'Previous',
        type: 'previous',
        class: 'secondary',
        disable: false,
      },
      {
        title: 'Next',
        type: 'next',
        class: 'primary',
        disable: !this.navs[this.stepNumber - 1].state,
      },
    ];
    this.skip = [];
    if (this.navs[this.stepNumber - 1].rule === 'optional') {
      this.skip = [
        {
          title: 'Skip for now',
          type: 'next',
          class: 'inverted',
        },
      ];
    }
    if (
      this.navs[this.stepNumber - 1].slug === 'your-sweet-spot' &&
      !this.contextService.validSelection['sweetSpot']
    ) {
      this.buttons.push({
        title: 'Use all the sliders to indicate your preferences.',
        type: '',
        class: 'none',
      });
    }
  }
  async step(value: string) {
    await new Promise((resolve) => {
      this.saveData(this.selectedNav).then((response) => {
        this.validateStep(response);
        resolve(true);
      });
    });
    this.rightContent.nativeElement.scrollTop = 0;
    window.scrollTo(0, 0);
    this.setButtons(value);
    if (window.innerWidth <= 1164) {
      this.openSidePanel = false;
    }
    sessionStorage.setItem('profile-flow', value);
    this.selectedNav = value;

    this.stepNumber =
      this.navs.findIndex((x) => x.slug === this.selectedNav) + 1;
  }
  setValid() {
    if (!this.stepsMenuSlug) {
      return;
    }
    this.navs = this.contextService.stepsMenu;
    this.navs.map((element) => {
      if (element.slug === this.stepsMenuSlug.game) {
        element.state = this.contextService.validSelection['game'];
      }
      if (element.slug === this.stepsMenuSlug.roles) {
        element.state =
          this.contextService.validSelection['asConsultant'] &&
          this.contextService.validSelection['jobFamily'] &&
          this.contextService.validSelection['yearExperience'] &&
          this.contextService.validSelection['levelWork'];
      }
      if (element.slug === this.stepsMenuSlug.allSkills) {
        element.state = this.contextService.validSelection['skills'];
      }
      if (element.slug === this.stepsMenuSlug.topSkills) {
        element.state = this.contextService.validSelection['topSkills'];
      }
      if (element.slug === this.stepsMenuSlug.sweetspot) {
        element.state = this.contextService.validSelection['sweetSpot'];
      }
      if (element.slug === this.stepsMenuSlug.experience) {
        element.state = this.contextService.validSelection['jobHistory'];
      }
      if (element.slug === this.stepsMenuSlug.technologies) {
        element.state = this.contextService.validSelection['technologies'];
      }
      if (element.slug === this.stepsMenuSlug.languages) {
        element.state = this.contextService.validSelection['languages'];
      }
      if (element.slug === this.stepsMenuSlug.location) {
        element.state =
          this.contextService.validSelection['city'] &&
          this.contextService.validSelection['workFrom'];
      }
      if (element.slug === this.stepsMenuSlug.income) {
        element.state =
          this.contextService.validSelection['rate'] &&
          this.contextService.validSelection['salary'] &&
          this.contextService.validSelection['currency'] &&
          this.contextService.validSelection['workTime'] &&
          this.contextService.validSelection['startDate'];
      }
      if (element.slug === this.stepsMenuSlug.profPro) {
        element.state = this.contextService.validSelection['profProfile'];
      }
      if (element.slug === this.stepsMenuSlug.finish) {
        element.state = this.contextService.validSelection['submitted'];
      }
    });
    this.readyForSubmit = !this.contextService.stepsMenu
      .filter(
        (x) => x.slug !== 'submit-for-approval' && x.slug !== 'finish-profile'
      )
      .some((x) => x.state === false && x.rule !== 'optional');
    this.setButtons(this.selectedNav);
  }
}

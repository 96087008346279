import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { ContextService } from 'src/services/platform/context.service';
import { PlatformService } from 'src/services/platform/platform.service';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';

import { DeviceDetectorService } from 'ngx-device-detector';
import { ApiService } from 'src/services/api/api.service';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { buttons } from 'src/ts/interfaces/shared.interface';
import { stepsMenuSlug } from 'src/ts/interfaces/profile.interface';
@Component({
  selector: 'app-game-assessments',
  templateUrl: './game-assessments.component.html',
  styleUrls: ['./game-assessments.component.scss'],
})
export class GameassessmentsComponent implements OnInit, OnDestroy {
  @Output() callbackFunction = new EventEmitter<buttons>();
  @Input() gameText: any;
  @Input() stepsMenuSlug: stepsMenuSlug;

  selectedGame: string = '';
  games: any = [];
  play: buttons[] = [
    { title: 'Previous', type: 'previous', class: 'secondary' },
    { title: 'Play', type: 'play', class: 'primary' },
  ];
  buttons: buttons[] = [
    { title: 'Previous', type: 'previous', class: 'secondary' },
    { title: 'Next', type: 'next', class: 'primary' },
  ];
  nextBtn: buttons[] = [{ title: 'Next', type: 'next', class: 'primary' }];
  gameDone = false;
  dialog = localStorage.getItem('gameDialog') !== 'false';
  gameUrl: SafeResourceUrl = '';
  isSafari = false;
  doneMessage = false;
  interval: ReturnType<typeof setInterval>;
  languages = this.contextService.languages.game;
  faCheck = faCheck;
  faChevronDown = faChevronDown;
  faTimes = faTimes;
  faqOpen = 0;
  rule: string;
  constructor(
    public platformService: PlatformService,
    private router: Router,
    public contextService: ContextService,
    private sanitizer: DomSanitizer,
    private deviceService: DeviceDetectorService,
    private apiService: ApiService
  ) {
    this.contextService.dataLoaded.subscribe((val) => {
      if (val) {
        this.games = this.contextService.languages.game.eachGame;

        if (
          this.contextService.myProfileData.assessments?.assignedAssessment
            ?.status === 'Created'
        ) {
          this.gameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.contextService.myProfileData.assessments.assignedAssessment
              .gameUrl
          );
        } else {
          this.dialog = false;
        }
        if (
          this.contextService.myProfileData.assessments?.assignedAssessment
            ?.status === 'Completed'
        ) {
          this.gameDone = true;
          this.contextService.validSelection['game'] = true;
          this.contextService.selectedGame.played = true;
        }
      }
    });
    window.addEventListener(
      'message',
      (e) => {
        if (e.data.status === 'games_finished') {
          this.doneMessage = true;
          this.nextBtn[0].class = 'primary';
        }
      },
      false
    );
  }
  getIcon(number: number) {
    if (number === this.faqOpen) {
      return faChevronUp;
    }
    return faChevronDown;
  }
  openFaq(number: number) {
    this.faqOpen = this.faqOpen === number ? 0 : number;
  }
  home() {
    this.router.navigate(['/home'], { queryParamsHandling: 'preserve' });
  }
  ngOnInit(): void {
    const findStep = this.contextService.stepsMenu.find(
      (x) => x.slug === 'game-based-assessments'
    );
    if (findStep) {
      this.rule = findStep.rule;
    }
  }
  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  finish(value: string) {
    this.selectedGame = '';
    this.gameDone = true;
    this.contextService.validSelection['game'] = true;
    this.contextService.selectedGame.played = true;
    this.contextService.myProfileData.assessments.assignedAssessment.status =
      'Completed';
  }
  closeDialog(state: boolean) {
    localStorage.setItem('gameDialog', JSON.stringify(state));
    this.dialog = false;
  }
  step(value: buttons) {
    if (value.type === 'play') {
      if (this.isSafari) {
        window.open(
          this.contextService.myProfileData.assessments.assignedAssessment
            .gameUrl,
          '_blank'
        );
        this.interval = setInterval(() => {
          this.apiService
            .getAssessment(
              '/profile/my',
              this.contextService.accessTokens.assessments
            )
            .then((response) => {
              if (response.assignedAssessment.status === 'Completed') {
                this.contextService.myProfileData.assessments = response;
                this.gameDone = true;
                clearInterval(this.interval);
              } else {
                console.log('Waiting for game to finish');
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }, 5000);
        return;
      }
      this.nextBtn[0].class = 'inverted';
      this.selectedGame = value.type;
      return;
    }

    this.callbackFunction.emit(value);
  }
}
